import {createStylesParams, StyleParamType, wixColorParam} from '@wix/tpa-settings'

export type MobileDesignStylesParams = {
  mobileTitleFontSize: StyleParamType.Number
  mobileSubtitleFontSize: StyleParamType.Number
  mobileBodyTextFontSize: StyleParamType.Number
  mobileLinkFontSize: StyleParamType.Number
  mobileDividerWidth: StyleParamType.Number
  mobileDividerColor: StyleParamType.Color
}

export const mobileDesignStylesParams = createStylesParams<MobileDesignStylesParams>({
  mobileTitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 28,
  },
  mobileSubtitleFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobileBodyTextFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobileLinkFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobileDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  mobileDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
})
