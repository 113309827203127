import {createStylesParams} from '@wix/tpa-settings'

import {mobileStyleParams, MobileStylesParams} from './Settings.mobile/stylesParams'
import {designStyleParams, DesignStylesParams} from './Settings/Tabs/Design/stylesParams'

type DesktopStylesParams = DesignStylesParams

type StylesParams = DesktopStylesParams & MobileStylesParams

export default createStylesParams<StylesParams>({
  ...designStyleParams,
  ...mobileStyleParams,
})
