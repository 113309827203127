import {StylesParamsType} from '../../../types/createStylesParams'

import {mobileDesignStylesParams, MobileDesignStylesParams} from './Tabs/Design/stylesParams'
import {mobileLayoutStylesParams, MobileLayoutStylesParams} from './Tabs/Layout/stylesParams'

export type MobileStylesParams = MobileDesignStylesParams & MobileLayoutStylesParams

export const mobileStyleParams: StylesParamsType<MobileStylesParams> = {
  ...mobileDesignStylesParams,
  ...mobileLayoutStylesParams,
}
