import React, {FC} from 'react'
import {Status} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {WidgetProps} from '@wix/yoshi-flow-editor'
import {Card, Text, Divider, TextButton, TextButtonPriority} from 'wix-ui-tpa'
import {utcToZonedTime} from '@wix/table-reservations-lib/timezone'
import {useSettings, useStyles} from '@wix/tpa-settings/react'

import {Map} from '../components/Map'
import {getLongDate} from '../../../utils/dateString'
import {CancelModal} from '../components/CancelModal'
import {ErrorModal} from '../components/ErrorModal'
import {withReservationConfirmationStorageProvider} from '../storage'
import stylesParams from '../stylesParams'
import {RtlProvider} from '../../../components-shared/RtlProvider'
import {textsSettingsParams as settingsParams} from '../Settings/Tabs/Texts/settingsParams'

import {useHooks} from './useHooks'
import {st, classes} from './Widget.st.css'
import {
  ALLOWED_STATUSES,
  reservationConfirmationDataHooks,
  STATUS_TO_CTA_BUTTON_DATA_HOOK_MAP,
  STATUS_TO_KEYS_MAP,
} from './constants'

const Widget: FC<WidgetProps> = () => {
  const {
    t,
    isMobile,
    isCancelDialogOpened,
    reservation,
    formattedAddress,
    locationName,
    calendarHref,
    messageType,
    timeZone,
    goToNewReservation,
    openCancelDialog,
    closeCancelDialog,
    phoneNumber,
    regionalSettings,
  } = useHooks()
  const styles = useStyles()
  const settings = useSettings()

  const titleTag =
    styles.get(stylesParams.titleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.titleFont).htmlTag

  const subtitleTag =
    styles.get(stylesParams.subtitleFont).htmlTag ??
    styles.getDefaultValue(stylesParams.subtitleFont).htmlTag

  const formattedDate = getLongDate(
    utcToZonedTime(reservation?.details?.startDate ?? new Date(), timeZone),
    regionalSettings,
  )

  if (!reservation?.id) {
    return (
      <RtlProvider>
        <main
          className={st(classes.root, {
            mobile: isMobile,
          })}
        >
          <Text tagName={titleTag} className={classes.title}>
            {t('uou-reservations.reservation-confirmation.refresh-page-error')}
          </Text>
        </main>
      </RtlProvider>
    )
  }

  if (!(ALLOWED_STATUSES as unknown as Status[]).includes(reservation.status!)) {
    return (
      <RtlProvider>
        <main
          className={st(classes.root, {
            mobile: isMobile,
          })}
        >
          <Text tagName={titleTag} className={classes.title}>
            {t('uou-reservations.reservation-confirmation.no-available')}
          </Text>
        </main>
      </RtlProvider>
    )
  }

  let titleText

  switch (reservation.status) {
    case Status.RESERVED: {
      titleText = settings.get(settingsParams.titleReservedText)
      break
    }
    case Status.REQUESTED: {
      titleText = settings.get(settingsParams.titleRequestedText)
      break
    }
    default: {
      titleText = t(STATUS_TO_KEYS_MAP[reservation.status!].title)
    }
  }

  return (
    <RtlProvider>
      <main
        className={st(classes.root, {
          mobile: isMobile,
        })}
        data-hook={reservationConfirmationDataHooks.root()}
      >
        <Text
          tagName={titleTag}
          className={classes.title}
          role={reservation.status === Status.CANCELED ? 'alert' : undefined}
        >
          {titleText}
        </Text>
        <Text tagName={subtitleTag} className={classes.subtitle}>
          {t(STATUS_TO_KEYS_MAP[reservation.status!].subtitle, {messageType})}
        </Text>

        {isMobile && <Divider className={classes.mobileDivider} />}

        <Card className={classes.reservationDetails}>
          <Card.Container className={classes.reservationInfo}>
            <div className={classes.reservationInfoWrap}>
              <div className={classes.reservationInfoText}>
                <Text
                  className={st(classes.reservationInfoTextTitle, classes.bodyText)}
                  tagName="p"
                >
                  {t(STATUS_TO_KEYS_MAP[reservation.status!].details, {
                    formattedDate,
                    partySize: t('uou-reservations.shared.guests', {
                      number: reservation.details?.partySize ?? 0,
                    }),
                  })}
                </Text>

                {reservation.status !== Status.DECLINED ? (
                  <>
                    <Text className={classes.bodyText}>
                      {formattedDate}
                      {!isMobile ? ', ' : ''}
                    </Text>
                    {isMobile ? <br /> : null}
                    <Text className={classes.bodyText}>
                      {t('uou-reservations.shared.guests', {
                        number: reservation.details?.partySize ?? 0,
                      })}
                    </Text>
                  </>
                ) : null}
              </div>

              {reservation.status === Status.RESERVED ? (
                <TextButton
                  as="a"
                  href={calendarHref}
                  // @ts-expect-error issue in types
                  target="_blank"
                  className={classes.linkButton}
                  priority={TextButtonPriority.primary}
                >
                  {t('uou-reservations.reservation-confirmation.calendar-cta')}
                </TextButton>
              ) : null}
            </div>

            {reservation.status === Status.DECLINED && reservation.declineReason ? (
              <div className={classes.declineReasonWrapper}>
                <Text className={classes.bodyText}>"{reservation.declineReason}"</Text>
              </div>
            ) : null}

            {!isMobile && <Divider className={classes.divider} />}

            <TextButton
              className={classes.linkButton}
              priority={TextButtonPriority.primary}
              data-hook={STATUS_TO_CTA_BUTTON_DATA_HOOK_MAP[reservation.status!]}
              onClick={() => {
                if (
                  reservation.status === Status.RESERVED ||
                  reservation.status === Status.REQUESTED
                ) {
                  openCancelDialog()
                } else {
                  goToNewReservation(reservation)
                }
              }}
            >
              {t(STATUS_TO_KEYS_MAP[reservation.status!].cta)}
            </TextButton>
          </Card.Container>
        </Card>

        {isMobile && <Divider className={classes.mobileDivider} />}

        <Card className={classes.locationDetails}>
          <Card.Container className={classes.locationInfo}>
            <Text className={classes.bodyText} tagName="h2">
              {t('uou-reservations.reservation-confirmation.address-text')}
            </Text>
            <Text className={classes.bodyText} tagName="p">
              {locationName}
            </Text>
            <Text className={classes.bodyText} tagName="p">
              {formattedAddress}
            </Text>
          </Card.Container>
          <Card.Container className={classes.locationMap}>
            <Map address={formattedAddress} />
          </Card.Container>
        </Card>

        {phoneNumber ? (
          <div className={classes.phoneNumberWrapper}>
            {/* @ts-expect-error */}
            <Text className={classes.bodyText} tagName="a" href={`tel:${phoneNumber}`}>
              {t('uou-reservations.reservation-confirmation.questions-text', {phoneNumber})}
            </Text>
          </div>
        ) : null}

        <CancelModal isOpen={isCancelDialogOpened} onClose={closeCancelDialog} />
        <ErrorModal />
      </main>
    </RtlProvider>
  )
}

export default withReservationConfirmationStorageProvider(Widget)
