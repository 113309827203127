import {Status} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {dataHooks} from '@wix/data-hooks'

export const ALLOWED_STATUSES = [
  Status.RESERVED,
  Status.CANCELED,
  Status.REQUESTED,
  Status.DECLINED,
] as const

export const STATUS_TO_KEYS_MAP: Record<
  (typeof ALLOWED_STATUSES)[number],
  {title: string; subtitle: string; cta: string; details: string}
> = {
  [Status.RESERVED]: {
    title: 'uou-reservations.reservation-confirmation.title',
    subtitle: 'uou-reservations.reservation-confirmation.subtitle',
    cta: 'uou-reservations.reservation-confirmation.cancel-reservation-cta',
    details: 'uou-reservations.reservation-confirmation.reservation-details',
  },
  [Status.CANCELED]: {
    title: 'uou-reservations.reservation-confirmation.cancelled-title',
    subtitle: 'uou-reservations.reservation-confirmation.cancelled-subtitle',
    cta: 'uou-reservations.reservation-confirmation.new-reservation-cta',
    details: 'uou-reservations.reservation-confirmation.cancelled-reservation-details',
  },
  [Status.REQUESTED]: {
    title: 'uou-reservations.reservation-confirmation.requested-title',
    subtitle: 'uou-reservations.reservation-confirmation.requested-subtitle',
    cta: 'uou-reservations.reservation-confirmation.cancel-request-cta',
    details: 'uou-reservations.reservation-confirmation.requested-reservation-details',
  },
  [Status.DECLINED]: {
    title: 'uou-reservations.reservation-confirmation.declined-title',
    subtitle: 'uou-reservations.reservation-confirmation.declined-subtitle',
    cta: 'uou-reservations.reservation-confirmation.new-reservation-cta',
    details: 'uou-reservations.reservation-confirmation.declined-reservation-details',
  },
}

export const reservationConfirmationDataHooks = dataHooks<{
  root: {}
  cancelReservationModalButton: {}
  cancelReservationPhoneInput: {}
  cancelReservationButton: {}
  makeNewReservationButton: {}
}>('reservation-confirmation')

export const STATUS_TO_CTA_BUTTON_DATA_HOOK_MAP: Record<(typeof ALLOWED_STATUSES)[number], string> =
  {
    [Status.RESERVED]: reservationConfirmationDataHooks.cancelReservationModalButton(),
    [Status.REQUESTED]: reservationConfirmationDataHooks.cancelReservationModalButton(),
    [Status.CANCELED]: reservationConfirmationDataHooks.makeNewReservationButton(),
    [Status.DECLINED]: reservationConfirmationDataHooks.makeNewReservationButton(),
  }
